import React from 'react';
import { useDrop } from 'react-dnd';
import DragItem from './DragItem';
import { Col, Row } from 'antd';
import { imageBoxContainer } from './styles';


const DropZone = ({ onDrop, items }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'item',
        drop: (item) => onDrop(item),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    }));

    return (
        <div ref={drop} style={{ height: '100%' }}>
            <Row>
            {items?.map((item, index) => (
                <Col lg={12} span={24} key={index}>
                <div css={imageBoxContainer}>
                    <DragItem key={item.id} file={item}/>
                </div>
                </Col>
            ))}
            </Row>
        </div>
    );
};

export default DropZone;