import { theme } from 'styles';

export const spinner = {
  margin: '1.875em 1.875em !important',
};

export const navigationWrapper = {
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '1em',

  '.specs-container': {
    display: 'flex',
    alignItems: 'center',
    marginRight: '2em',
    '& button': {
      paddingLeft: '0',
      paddingRight: '0.5em',
    },
    '& .business-name': {
      fontSize: '1.375rem',
      fontWeight: 500,
      color: theme.background100,
      marginRight: '0.625em',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
    },
  },

  '.actions-container': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5em',
    marginBottom: '1em',
  },

  '& .la-arrow-left': {
    fontSize: '1.375rem',
    color: theme.background100,
    marginTop: '-0.25em',
  },
};

export const businessDetailsWrapper = {
  display: 'flex',
  alignItems: 'flex-start',
  paddingBottom: '0.75em',
  '& span': {
    color: theme.blue2,
    marginRight: '0.625em',
  },
  '& .la-globe': {
    fontSize: '1.125rem',
  },
  '& label': {
    color: theme.gray100,
  },
};

export const subTitle = {
  display: 'inline-block',
  color: theme.background100,
  fontSize: '1rem',
  fontWeight: 700,
  marginBottom: '0.625em',
};

export const informationWrapper = {
  borderBottom: `1px solid ${theme.gray500}`,
  '& label': {
    display: 'inline-block',
    color: theme.background200,
    marginBottom: '0.625em',
  },
  '& p': {
    marginBottom: '0.625em',
  },
  '& .ant-tabs-top > .ant-tabs-nav::before': {
    borderBottom: 'none',
  },
  '& .ant-tabs-nav-wrap': {
    marginBottom: '0.625em',
  },
  [theme.Desktop]: {
    paddingLeft: '1em',
  },
};

export const specificBusinessInfoWrapper = {
  '& label': {
    display: 'inline-block',
    color: theme.background200,
    marginBottom: '0.625em',
  },
  '& p': {
    marginBottom: '0.625em',
  },
  '& .ant-tabs-top > .ant-tabs-nav::before': {
    borderBottom: 'none',
  },
  '& .ant-tabs-nav-wrap': {
    marginBottom: '0.625em',
  },
  [theme.Desktop]: {
    paddingLeft: '1em',
  },
};

export const editFormSectionBtn = {
  padding: '0.3em',
  minHeight: 'auto',
  marginLeft: '0.5em',
};

export const downLoadBtn = {
  fontSize: '1rem',
  maxHeight: '2.5rem',
  '& a': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .inPopup': {
    marginLeft: '0.375em',
  },
};

export const toggleHiddenText = {
  width: '15.625rem',
  maxWidth: '100%',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  transition: 'all .5s ease-in-out',
  '&:hover': {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    whiteSpace: 'pre-wrap',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    wordBreak: 'break-word',
  },
};

export const uploadedFile = {
  display: 'flex',
  flexDirection: 'column',
  '& div.overlay': {
    display: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
  },
  '& .file-name': {
    fontWeight: 500,
    fontSize: '1rem',
    maxWidth: '14rem',
  },
};

export const imgContainer = {
  position: 'relative',
  padding: 0,
  width: '100%',
  aspectRatio: '1/1',
  overflow: 'hidden',
  '&:hover div.overlay': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    zIndex: 1,
  },
};

export const imageStyles = {
  width: '100%',
  height: 'auto',
  position: 'absolute',
  top: 0,
};

export const popupContainer = {
  '& img': {
    display: 'block',
    margin: '0 auto',
  },
  '& .file-name': {
    fontSize: '1.125rem',
    fontWeight: 500,
    margin: 0,
  },
  '& .file-description': {
    fontSize: '1rem',
    color: theme.gray300,
    margin: 0,
  },
};

export const mt = (mar) => ({
  marginTop: mar.lg,
  [theme.Mobile]: { marginTop: mar.span },
});

export const imageBoxContainer = {
  padding: '3px',
  borderRadius: '5px',
  margin: '5px',
  boxShadow: '0px 0px 4px 1px #00000014',
  'i.la-eye': {
    color: 'white',
  },
};