import PropTypes from 'prop-types';
import { isNil } from '@veraio/core';
import { Skeleton } from 'antd';
import { CommentModal, LoadMoreText } from 'components/ui';
import { formatDefaultDateTime } from 'utils';
import { commentsListContainer } from './styles';
import { editFormSectionBtn } from '../../../screens/Private/DealDetailPage/tabs/DealInformationTab/styles.js';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal } from '../../UIExternal/index.js';
import { useState } from 'react';
import { actionButtons } from '../../screens/Managers/ConfirmPopup/styles.js';
import { getText } from '@veraio/strank';

export const CommentsList = ({ comments, editButton, deleteButton, handleEdit, handleDelete }) => {

  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [editedCommentId, setEditedCommentId] = useState(null);
  const [deletedCommentId, setDeletedCommentId] = useState(null);


  const handleOpenEditCommentModal = async (commentId) => {
    setEditedCommentId(commentId);
    setIsCommentModalVisible(true);
  };

  const handleEditComment = async (commentText) => {
    handleEdit(editedCommentId, commentText);
    setEditedCommentId(null);
    setIsCommentModalVisible(false);
  };

  const handleConfirmDelete = async () => {
    handleDelete(deletedCommentId);
    setDeletedCommentId(null);
    setIsConfirmModalVisible(false);
  };

  const handleCloseConfirmModal = async () => {
    setDeletedCommentId(null);
    setIsConfirmModalVisible(false);
  };

  const handleOpenConfirmModal = async (commentId) => {
    setDeletedCommentId(commentId);
    setIsConfirmModalVisible(true);
  };

  return isNil(comments) ? (
    <Skeleton count={4} width={270} height={22} />
  ) : (
    <>
      <ul css={commentsListContainer}>
        {comments.map((item) => (
          <li key={item.createdDate} css={{ marginBottom: '0.25em' }}>
          <span className="bolder-text">{`${item.managerName ?? item.createdBy} [${formatDefaultDateTime(
            item.createdDate,
          )}]: `}</span>
            <LoadMoreText text={item.comment} />
            {editButton &&
              <Button type="default" onClick={() => handleOpenEditCommentModal(item.id)} css={editFormSectionBtn}>
                <EditOutlined />
              </Button>
            }
            {deleteButton && <Button type="default" onClick={() => handleOpenConfirmModal(item.id)} css={editFormSectionBtn}>
              <DeleteOutlined />
            </Button>
            }
          </li>
        ))}
      </ul>
      <CommentModal
        isVisible={isCommentModalVisible}
        onCancel={() => setIsCommentModalVisible(false)}
        onAccept={handleEditComment}
        title={getText('edit')}
      />
      <Modal modalTitle={getText('areYouSure')} show={isConfirmModalVisible}>
        <h5>
          {getText('sureRemoveThisComment')}
        </h5>
        <div css={actionButtons}>
          <Button small type="success" onClick={handleConfirmDelete}>
            {getText('apply')}
          </Button>
          <Button small type="danger" onClick={handleCloseConfirmModal}>
            {getText('cancel')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

CommentsList.propTypes = {
  comments: PropTypes.array,
  editButton: PropTypes.bool,
  deleteButton: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
};
