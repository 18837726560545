import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { Collapse } from 'antd';
import { useUrlParams, Dropdown } from 'components/UIExternal';
import { DatePicker, TextInput } from 'components/ui';
import { PageHeader } from 'components/shared';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { businessStatusSelect, businessStatusColor, businessStatusInverse } from 'enums';
import { pageFilterCollapse } from 'styles';

export const BusinessesFilterBar = ({ paginationRef, filterKeys, defaultQueryParams }) => {
  const { queryParams } = useUrlParams();
  const queryStatus = queryParams[filterKeys.status] && queryParams[filterKeys.status].map(Number);
  const countries = useLocations((locationsState) => locationsState.countries);

  return (
    <>
      <PageHeader
        title={getText('businesses')}
        statusProps={{
          defaultValue: queryStatus ?? defaultQueryParams[filterKeys.status],
          onChange: (value) => paginationRef.current?.applyFilters({ [filterKeys.status]: value }),
          options: businessStatusSelect(),
          tagProps: { type: businessStatusColor, value: businessStatusInverse },
        }}
      />
      <Collapse css={pageFilterCollapse}>
        <Collapse.Panel header={getText('filters')}>
          <section className="filters-container">
            <div>
              <label>{getText('keyword')}</label>
              <TextInput
                showSearch
                showDelete
                placeholder={getText('searchByKeyword')}
                onSearch={(val) => paginationRef.current?.applyFilters({ [filterKeys.search]: val })}
                defaultValue={queryParams[filterKeys.search]}
              />
            </div>
            <div>
              <label>{getText('country')}</label>
              <Dropdown
                multiSelect={true}
                withSearch
                placeholder={getText('selectCountry')}
                options={countries}
                displayKey="name"
                uniqueKey="id"
                onChange={(selectedValues) =>
                  paginationRef.current?.applyFilters({
                    [filterKeys.countries]: selectedValues.map(option => option.id)
                  })
                }
              />
            </div>
            <div>
              <label>{getText('merchantName')}</label>
              <TextInput
                showDelete
                onSearch={(val) => paginationRef.current?.applyFilters({ [filterKeys.owner]: val })}
                placeholder={getText('searchByMerchantName')}
                defaultValue={queryParams[filterKeys.owner]}
              />
            </div>
            <div>
              <label>{getText('merchantUsername')}</label>
              <TextInput
                showDelete
                onSearch={(val) => paginationRef.current?.applyFilters({ [filterKeys.user]: val })}
                placeholder={getText('searchByMerchantUsername')}
                defaultValue={queryParams[filterKeys.user]}
              />
            </div>

            <div>
              <label>{getText('email')}</label>
              <TextInput
                showDelete
                onSearch={(val) => paginationRef.current?.applyFilters({ [filterKeys.email]: val })}
                placeholder={getText('searchByEmail')}
                defaultValue={queryParams[filterKeys.email]}
              />
            </div>
            <div className="flex-auto">
              <h5>{getText('submittedDate')}</h5>
              <div className="filters-date-pickers">
                <div>
                  <label>{getText('from')}</label>
                  <DatePicker
                    onChange={(value) =>
                      paginationRef.current?.applyFilters({ [filterKeys.creationDateFrom]: value || null })
                    }
                    defaultValue={queryParams[filterKeys.creationDateFrom]}
                  />
                </div>
                <div>
                  <label>{getText('to')}</label>
                  <DatePicker
                    onChange={(value) =>
                      paginationRef.current?.applyFilters({ [filterKeys.creationDateTo]: value || null })
                    }
                    defaultValue={queryParams[filterKeys.creationDateTo]}
                  />
                </div>
              </div>
            </div>
            <div className="flex-auto">
              <h5>{getText('lastModified')}</h5>
              <div className="filters-date-pickers">
                <div>
                  <label>{getText('from')}</label>
                  <DatePicker
                    onChange={(value) =>
                      paginationRef.current?.applyFilters({ [filterKeys.modifiedDateFrom]: value || null })
                    }
                    defaultValue={queryParams[filterKeys.modifiedDateFrom]}
                  />
                </div>
                <div>
                  <label>{getText('to')}</label>
                  <DatePicker
                    onChange={(value) =>
                      paginationRef.current?.applyFilters({ [filterKeys.modifiedDateTo]: value || null })
                    }
                    defaultValue={queryParams[filterKeys.modifiedDateTo]}
                  />
                </div>
              </div>
            </div>
          </section>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

BusinessesFilterBar.propTypes = {
  paginationRef: PropTypes.object,
  filterKeys: PropTypes.object,
  defaultQueryParams: PropTypes.object,
};
