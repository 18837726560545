import { useState, forwardRef, useEffect } from 'react';
import { Button, getTextChangeProps } from 'components/UIExternal';
import { useTranslations } from '@veraio/strank';
import { isEmpty } from 'lodash-es';
import { isMobile } from 'react-device-detect';
import { Col, Row } from 'antd';
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { updateMediaCategory } from 'services/api/mediaService';
import { setError } from 'services';

import {
  MediaList,
  BusinessMainInformationPreview,
  BusinessAdditionalInformationPreview,
  BusinessAddressPreview,
  ContactInformationPreview,
  BusinessMainInformationEdit,
  BusinessAdditionalInformationEdit,
  BusinessAddressEdit,
  ContactInformationEdit,
  AddressCard,
} from 'components';
import { mb } from 'styles';
import { informationWrapper, subTitle, specificBusinessInfoWrapper } from './styles';
import { editFormSectionBtn } from './styles';
import DropZone from './DropZone';

export const BusinessInformationTab = forwardRef(
  ({ business, handleEditBusiness, businessWithAddress, oldData, newData }) => {
    const { getText } = useTranslations();
    const [isEditingMainInfo, setIsEditingMainInfo] = useState(false);
    const [isEditingAdditionalInfo, setIsEditingAdditionalInfo] = useState(false);
    const [isEditingBusinessAddress, setIsEditingBusinessAddress] = useState(false);
    const [isEditingContactInfo, setIsEditingContactInfo] = useState(false);
    const [draggableMediaList, setDraggableMedia] = useState([]);

    const categories = [
      {
        id: 1,
        name: 'uploadedFiles'
      },
      {
        id: 2,
        name: 'identificationDropZone'
      },
      {
        id: 3,
        name: 'businessRegistrationDropZone'
      },
    ];

    const handleEditMainInformation = () => {
      setIsEditingMainInfo((prev) => !prev);
    };

    const handleEditBusinessAddress = () => {
      setIsEditingBusinessAddress((prev) => !prev);
    };

    const handleEditAdditionalInformation = () => {
      setIsEditingAdditionalInfo((prev) => !prev);
    };

    const handleEditContactInformation = () => {
      setIsEditingContactInfo((prev) => !prev);
    };


    const textChangeProps = getTextChangeProps(oldData, newData);

    const handleDrop = async (item, newCategoryId) => {
      if (item.file.categoryId === newCategoryId) return; // Prevent dropping into the same zone

      setDraggableMedia((prev) => {
        let newList = prev.map((arr) => [...arr]);
        newList[item.file.categoryId - 1] = newList[item.file.categoryId - 1].filter(
          (i) => i.id !== item.file.id
        );

        item.file.categoryId = newCategoryId;
        newList[newCategoryId - 1] = [...newList[newCategoryId - 1], item.file];

        return newList;
      });

      const [, err] = await updateMediaCategory(item.file?.id, newCategoryId);
      if (err) setError(err);
    };

    useEffect(() => {
      const categorizedData = business?.media.reduce(
        (acc, item) => {
          const index = item.categoryId - 1;
          if (index >= 0 && index < acc.length)
            acc[index].push(item);

          return acc;
        },
        Array.from({ length: categories.length }, () => [])
      );

      setDraggableMedia(categorizedData);

    }, []);

    return (
      <>
        {/* here starts the business components */}
        <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
          <Col lg={24} span={24}>
            <label css={subTitle}>{getText('mainInformation')}</label>
            <Button type="default" css={editFormSectionBtn} onClick={handleEditMainInformation}>
              {isEditingMainInfo ? <CloseOutlined /> : <EditOutlined />}
            </Button>
          </Col>
          {isEditingMainInfo ? (
            <BusinessMainInformationEdit
              business={business}
              onCancel={() => setIsEditingMainInfo(false)}
              onEdit={handleEditBusiness}
            />
          ) : (
            <BusinessMainInformationPreview business={business} textChange={textChangeProps} />
          )}
        </Row>
        <Row css={[specificBusinessInfoWrapper, mb({ lg: 36, span: 30 })]}>
          <Col lg={24} span={24}>
            <label css={subTitle}>{getText('additionalInformation')}</label>
            <Button type="default" css={editFormSectionBtn} onClick={handleEditAdditionalInformation}>
              {isEditingAdditionalInfo ? <CloseOutlined /> : <EditOutlined />}
            </Button>
          </Col>
          {isEditingAdditionalInfo ? (
            <BusinessAdditionalInformationEdit
              business={business}
              onCancel={() => setIsEditingAdditionalInfo(false)}
              onEdit={handleEditBusiness}
            />
          ) : (
            <BusinessAdditionalInformationPreview business={business} textChange={textChangeProps} />
          )}
        </Row>
        <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
          <Col lg={24} span={24}>
            <label css={subTitle}>{getText('contactInformation')}</label>
            <Button type="default" css={editFormSectionBtn} onClick={handleEditContactInformation}>
              {isEditingContactInfo ? <CloseOutlined /> : <EditOutlined />}
            </Button>
          </Col>
          {isEditingContactInfo ? (
            <ContactInformationEdit
              business={business}
              onCancel={() => setIsEditingContactInfo(false)}
              onEdit={handleEditBusiness}
            />
          ) : (
            <ContactInformationPreview business={business} textChange={textChangeProps} />
          )}
        </Row>
        <Row css={[informationWrapper, mb({ lg: 36, span: 30 })]}>
          <Col lg={24} span={24}>
            <label css={subTitle}>{getText('businessAddressInformation')}</label>
            <Button type="default" css={editFormSectionBtn} onClick={handleEditBusinessAddress}>
              {isEditingBusinessAddress ? <CloseOutlined /> : <EditOutlined />}
            </Button>
          </Col>
          {isEditingBusinessAddress ? (
            <BusinessAddressEdit
              business={business}
              onCancel={() => setIsEditingBusinessAddress(false)}
              onEdit={handleEditBusiness}
            />
          ) : (
            <BusinessAddressPreview business={business} textChange={textChangeProps} />
          )}
        </Row>
        <DndProvider backend={HTML5Backend}>
            <div style={{ marginBottom: '20px' }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    width: '100%',
                    gap: '10px',
                }}>
                  {categories?.map((category, index) => (
                    <div key={index} style={{
                        padding: '15px',
                        boxShadow: '0px 0px 4px 1px #00000014',
                        flex: 1,

                    }}>
                        <h2><label css={subTitle}>{getText(category.name)}</label></h2>
                        <DropZone onDrop={(e) => handleDrop(e, category.id)}
                        items={draggableMediaList[category.id - 1]} />
                    </div>
                  ))}
                </div>
              </div>
        </DndProvider>
        <Row>
          <Col lg={24} span={24} style={{ paddingLeft: !isMobile && 16 }}>
            <label css={subTitle}>{getText('pickupLocations')}</label>
          </Col>
        </Row>
        <Row css={mb({ lg: 30, span: 20 })} gutter={[24, 24]}>
          {businessWithAddress?.businessAddresses?.map((address, ind) => (
            <Col className="gutter-row" lg={8} span={24} key={ind}>
              <AddressCard oldData={address} />
            </Col>
          ))}
        </Row>
      </>
    );
  },
);
