import PropTypes from 'prop-types';
import { Collapse, Row, Col, InputNumber } from 'antd';
import { useTranslations } from '@veraio/strank';
import { isNil } from '@veraio/core';
import { useUrlParams, Dropdown } from 'components/UIExternal';
import { DatePicker, TextInput } from 'components/ui';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { PageHeader } from 'components/shared';
import {
  dealStatusInverse,
  dealStatusColor,
  dealStatusSelect,
  dealPriceSignDirectionSelect,
  dealPriceSignDirection,
} from 'enums';
import { pageFilterCollapse } from 'styles';
import { CategoryFilter } from './CategoryFilter';
import { filterRow, categoriesContainer } from './styles';

export const DialsFilterBar = ({ paginationRef, filterKeys, defaultQueryParams }) => {
  const { queryParams, setQueryParams } = useUrlParams();
  const { getText } = useTranslations();
  const countries = useLocations((locationsState) => locationsState.countries);
  const queryStatus = queryParams[filterKeys.status] && queryParams[filterKeys.status]?.map((el) => Number(el));

  const handlePriceFilter = ({ priceValue, priceSign }) => {
    const filterPrices = {
      priceSign: priceSign ?? queryParams?.priceSign ?? dealPriceSignDirection.greater,
      priceValue: priceValue ?? queryParams?.priceValue,
    };

    setQueryParams(filterPrices);

    return (
      Number(filterPrices?.priceValue) >= 0 &&
      !isNil(filterPrices?.priceSign) &&
      paginationRef.current?.applyFilters({ ...filterPrices })
    );
  };

  return (
    <>
      <PageHeader
        title={getText('deals')}
        statusProps={{
          defaultValue: queryStatus ?? defaultQueryParams[filterKeys.status],
          onChange: (value) => paginationRef.current?.applyFilters({ [filterKeys.status]: value }),
          options: dealStatusSelect(),
          tagProps: { type: dealStatusColor, value: dealStatusInverse },
        }}
      />
      <Collapse css={pageFilterCollapse}>
        <Collapse.Panel header={getText('filters')}>
          <Row css={filterRow} gutter={40}>
            <Col lg={24}>
              <label className="section-subtitle">{getText('filterByCategory')}</label>
            </Col>
            <Col lg={6}>
              <div css={categoriesContainer}>
                <CategoryFilter
                  onChange={(val) => paginationRef.current?.applyFilters({ [filterKeys.category]: val })}
                  value={queryParams?.[filterKeys.category]}
                />
              </div>
            </Col>
            <Col sm={24} md={24} lg={18}>
              <Row css={filterRow} gutter={40}>
                <Col sm={12} md={12} lg={12}>
                  <TextInput
                    showDelete
                    placeholder={getText('searchByKeyword')}
                    defaultValue={queryParams?.[filterKeys.search]}
                    onSearch={(val) => paginationRef.current?.applyFilters({ [filterKeys.search]: val })}
                  />
                </Col>
                <Col sm={6} md={6} lg={6}>
                  <Dropdown
                    noClear
                    placeholder={getText('selectPriceCondition')}
                    options={dealPriceSignDirectionSelect()}
                    value={queryParams?.priceSign ?? dealPriceSignDirection.greater}
                    onChange={(val) => handlePriceFilter({ priceSign: val?.value })}
                  />
                </Col>
                <Col sm={6} md={6} lg={6}>
                  <InputNumber
                    style={{ width: '100%' }}
                    min={0}
                    value={queryParams?.priceValue}
                    placeholder={getText('searchByPrice')}
                    onPressEnter={(e) => handlePriceFilter({ priceValue: e?.target?.value })}
                  />
                </Col>

                <Col sm={24} md={24} lg={24}>
                <Dropdown
                  multiSelect={true}
                  withSearch
                  placeholder={getText('selectCountry')}
                  options={countries}
                  displayKey="name"
                  uniqueKey="id"
                  onChange={(selectedValues) =>
                    paginationRef.current?.applyFilters({
                      [filterKeys.countries]: selectedValues.map(option => option.id)
                    })
                  }
                />
                </Col>
                <Col sm={24} md={24} lg={24}>
                  <label className="section-subtitle merchant">{getText('merchant')}</label>
                </Col>
                <Col lg={8}>
                  <p>{getText('phoneNumber')}</p>
                  <TextInput
                    showDelete
                    defaultValue={String(queryParams?.[filterKeys.phone] ?? '')}
                    onSearch={(val) => paginationRef.current?.applyFilters({ [filterKeys.phone]: val })}
                    placeholder={getText('searchByPhoneNumber')}
                  />
                </Col>
                <Col lg={8}>
                  <p>{getText('username')}</p>
                  <TextInput
                    showDelete
                    defaultValue={queryParams?.[filterKeys.username]}
                    onSearch={(val) => paginationRef.current?.applyFilters({ [filterKeys.username]: val })}
                    placeholder={getText('searchByUsername')}
                  />
                </Col>
                <Col lg={8}>
                  <p>{getText('email')}</p>
                  <TextInput
                    showDelete
                    defaultValue={queryParams?.[filterKeys.email]}
                    onSearch={(val) => paginationRef.current?.applyFilters({ [filterKeys.email]: val })}
                    placeholder={getText('searchByEmail')}
                  />
                </Col>
                <Col lg={8}>
                  <div className="flex flex-column">
                    <h5 className="section-subtitle ">{getText('creationDate')}</h5>
                    <div className="flex flex-wrap" style={{ gap: '1em' }}>
                      <div className="flex flex-column">
                        <p>{getText('from')}</p>
                        <DatePicker
                          defaultValue={queryParams?.[filterKeys.creationDateFrom]}
                          onChange={(val) =>
                            paginationRef.current?.applyFilters({ [filterKeys.creationDateFrom]: val })
                          }
                        />
                      </div>
                      <div className="flex flex-column">
                        <p>{getText('to')}</p>
                        <DatePicker
                          defaultValue={queryParams?.[filterKeys.creationDateTo]}
                          onChange={(val) => paginationRef.current?.applyFilters({ [filterKeys.creationDateTo]: val })}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={8}>
                  <div className="flex flex-column">
                    <h5 className="section-subtitle ">{getText('publishDate')}</h5>
                    <div className="flex space-between flex-wrap" style={{ gap: '1em' }}>
                      <div className="flex flex-column">
                        <p>{getText('from')}</p>
                        <DatePicker
                          defaultValue={queryParams?.[filterKeys.publishDateFrom]}
                          onChange={(val) => paginationRef.current?.applyFilters({ [filterKeys.publishDateFrom]: val })}
                        />
                      </div>
                      <div className="flex flex-column">
                        <p>{getText('to')}</p>
                        <DatePicker
                          defaultValue={queryParams?.[filterKeys.publishDateTo]}
                          onChange={(val) => paginationRef.current?.applyFilters({ [filterKeys.publishDateTo]: val })}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={8}>
                  <div className="flex flex-column">
                    <h5 className="section-subtitle ">{getText('lastModified')}</h5>
                    <div className="flex space-between flex-wrap" style={{ gap: '1em' }}>
                      <div className="flex flex-column">
                        <p>{getText('from')}</p>
                        <DatePicker
                          defaultValue={queryParams?.[filterKeys.modifiedDateFrom]}
                          onChange={(val) =>
                            paginationRef.current?.applyFilters({ [filterKeys.modifiedDateFrom]: val })
                          }
                        />
                      </div>
                      <div className="flex flex-column">
                        <p>{getText('to')}</p>
                        <DatePicker
                          defaultValue={queryParams?.[filterKeys.modifiedDateTo]}
                          onChange={(val) => paginationRef.current?.applyFilters({ [filterKeys.modifiedDateTo]: val })}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </>
  );
};

DialsFilterBar.propTypes = {
  filterKeys: PropTypes.object,
  defaultQueryParams: PropTypes.object,
  paginationRef: PropTypes.object,
};
