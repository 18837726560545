import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useTranslations } from '@veraio/strank';
import { businessTypeInverse } from 'enums';
import { AddressCard } from 'components/shared';
import { mb } from 'styles';
import { subTitle, businessWrapper, textLinkStyle } from './styles';
import { Link } from 'react-router-dom';

export const DealBusinessAndFacilitiesTab = ({ deal }) => {
  const { getText } = useTranslations();

  return (
    <>
      <Row css={[businessWrapper, mb({ lg: 13, span: 13 })]}>
        <Col lg={24} span={24}>
          <label css={subTitle}>{getText('business')}</label>
        </Col>
        <Col lg={4} span={24}>
          <label>{getText('type')}</label>
        </Col>
        <Col lg={20} span={24}>
          <p>{getText(businessTypeInverse[deal.businessTypeId])}</p>
        </Col>
        <Col lg={4} span={24}>
          <label>{getText('title')}</label>
        </Col>
        <Col lg={20} span={24}>
          <Link to={`/business/${deal?.businessId}`} target="_blank" css={textLinkStyle} className="flex flex-column">
            {deal.businessTitle}
          </Link>
        </Col>
        <Col lg={4} span={24}>
          <label>{getText('description')}</label>
        </Col>
        <Col lg={20} span={24}>
          <p>{deal.businessDescription}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={24} span={24} style={{ paddingLeft: 16 }}>
          <label css={subTitle}>{getText('dealFacilities')}</label>
        </Col>
      </Row>
      <Row css={mb({ lg: 30, span: 20 })} gutter={[24, 24]}>
        {deal.dealAddresses?.map((addresses, index) => (
          <Col key={index} className="gutter-row" lg={8} span={24}>
            <AddressCard oldData={addresses} />
          </Col>
        ))}
      </Row>
    </>
  );
};

DealBusinessAndFacilitiesTab.propTypes = {
  deal: PropTypes.object,
};
