import React from 'react';
import { useDrag } from 'react-dnd';
import { useState } from 'react';
import { getText } from '@veraio/strank';
import { Button, Modal } from 'components/UIExternal';
import { mr } from 'styles';
import { Icon } from 'components/ui';
import { MediaTypesValidation } from 'enums/MediaTypesEnum';
import {
    uploadedFile,
    downLoadBtn,
    toggleHiddenText,
    imgContainer,
    imageStyles,
    popupContainer,
    mt
  } from './styles';
import { formatDefaultDateTime } from 'utils';

const DragItem = ({ file }) => {
    const handleUploadFile = (fileObject) => {
        fileObject.typeId === MediaTypesValidation.pdf.id && window.open(fileObject.url);
        setFileDetails({
            thumbnailUrl: file.thumbnailUrl,
            name: file.name,
            description: file.description,
            url: file.url,
            showPopUp: file.typeId !== MediaTypesValidation.pdf.id,
        });
    };

    const [fileDetails, setFileDetails] = useState({ showPopUp: false });

    const downloadImage = async (fileObj) => {
        const image = await fetch(fileObj.url);
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);

        const link = document.createElement('a');
        link.href = imageURL;
        link.download = fileObj.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'item',
        item: { file },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    return (
        <div
            ref={drag}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move',
                borderRadius: '5px',
                margin: '5px',
            }}>
            <div css={uploadedFile} key={file.id}>
                <Button type="default" css={imgContainer} onClick={() => handleUploadFile(file)}>
                    <div className="overlay">
                        <Icon iconName="lar la-eye" size={40} />
                    </div>
                    {file.typeId === MediaTypesValidation.pdf.id ? (
                        <Icon iconName="las la-file" size={100} />
                    ) : (
                        <img src={file.thumbnailUrl} alt="" css={imageStyles} />
                    )}
                </Button>
                {!!file.description && <p css={toggleHiddenText}>{file.description}</p>}
                {!!file.createdDate && <p css={toggleHiddenText}>{formatDefaultDateTime(file.createdDate)}</p>}

                <Button type="secondary" fullWidth css={downLoadBtn} onClick={() => downloadImage(file)}>
                    <Icon iconName="las la-download" size={20} css={mr({ lg: 5 })} />
                    <span>{getText('download')}</span>
                </Button>
            </div>

            <Modal show={fileDetails.showPopUp}
            onClose={() => setFileDetails((prev) => ({ ...prev, showPopUp: false }))}>
                <div css={popupContainer}>
                    <img src={fileDetails.thumbnailUrl} alt="" />
                    <div css={mt({ lg: 20 })} className="flex">
                    <div css={mr({ lg: 10 })}>
                        <p css={toggleHiddenText} className="file-name">
                        {fileDetails.name}
                        </p>
                        <p css={toggleHiddenText} className="file-description">
                        {fileDetails.description}
                        </p>
                        {!!file.createdDate && <p css={toggleHiddenText}>{formatDefaultDateTime(file.createdDate)}</p>}
                    </div>
                    <Button type="secondary" fullWidth css={downLoadBtn} onClick={() => downloadImage(fileDetails)}>
                        <Icon iconName="las la-download" size={20} />
                        <span className="inPopup">{getText('download')}</span>
                    </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default DragItem;